<template>
  <div class="event-source-wall">
    <div style="margin-bottom: 16px">
      <create-button v-permission="{action: 'base.event_source.create', effect: 'disabled'}" text="创建事件源" @click="$refs.inputDrawer.show()">
      </create-button>
      <a-space style="float: right" :size="16">
        <a-input-search
          class="event-source-search"
          placeholder="请输入事件源名称"
          v-model="searchText"
          @search="handleSearch"
        ></a-input-search>
        <reload-button @click="fetch()"></reload-button>
      </a-space>
    </div>

    <div style="clear: both;"></div>

    <a-collapse :bordered="false" v-model="activeKey">
      <a-collapse-panel
        key="internal"
        :showArrow="false"
        :style="customStyle"
      >
        <template slot="header">
          <div class="collapse-title">
            <span>事件源列表</span>
            <a-icon
              class="collapse-icon"
              :type="collapseIcon('important')"
              theme="filled"
            ></a-icon>
          </div>
        </template>
        <a-spin :spinning="spinning">
          <a-row v-if="dataSource.length" :gutter="16">
            <a-col
              v-for="item in dataSource"
              :key="item.id"
              :md="12"
              :lg="8"
              :xxl="6"
              style="margin-bottom: 16px"
            >
              <event-source-card
                :eventSource="item"
                @ok="v => confirm(v)"
              ></event-source-card>
            </a-col>
            <a-col :span="24" style="margin-top:36px;">
              <div style="height: 32px">
                <a-pagination
                  :current="fetchParams.page"
                  :page-size="fetchParams.page_size"
                  :page-size-options="['12', '24', '36', '48']"
                  :show-size-changer="true"
                  :show-total="t => `共 ${t} 条`"
                  :total="total"
                  @change="
                    page => {
                      fetchParams.page = page
                      fetch()
                    }
                  "
                  @showSizeChange="
                    (_, pageSize) => {
                      fetchParams.page = 1
                      fetchParams.page_size = pageSize
                      fetch()
                    }
                  "
                  style="float: right"
                ></a-pagination>
              </div>
            </a-col>
          </a-row>
            <div v-else style="height: 320px; position: relative; overflow: hidden;">
              <empty-component
                :imgUrl="require('@/assets/images/info_empty.png')"
                :body-style="{
                  height: '220px',
                  marginTop: '28px'
                }"
                :description-style="{
                  marginTop: '-12px',
                  marginRight: '8px',
                  color: 'rgba(0,0,0,.55)'
                }"
                description="暂无数据"
              >
              </empty-component>
            </div>
        </a-spin>
      </a-collapse-panel>
    </a-collapse>
    <input-drawer ref="inputDrawer" @ok="confirm"></input-drawer>
  </div>
</template>

<script>
import { getEventSourceList, deleteEventSource } from '@/api/event-source'
import EventSourceCard from './modules/EventSourceCard.vue'
import InputDrawer from './modules/InputDrawer.vue'
import CreateButton from '@/components/button/CreateButton.vue'
import { updateCurrentPage } from '@/utils'
import ReloadButton from '@/components/button/ReloadButton.vue'
import EmptyComponent from '@/components/EmptyComponent.vue'

export default {
  name: 'EventSource',
  components: {
    EventSourceCard,
    InputDrawer,
    CreateButton,
    ReloadButton,
    EmptyComponent
  },
  data () {
    return {
      activeKey: ['internal'],
      spinning: false,
      dataSource: [],
      customStyle: {
        background: '#ffffff',
        borderRadius: '8px',
        marginBottom: '16px',
        minHeight: '400px',
        border: 0
      },
      total: 0,
      fetchParams: {
        page: 1,
        page_size: 12
      },
      searchText: ''
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch (update = false) {
      this.spinning = true
      if (update) {
        this.fetchParams.page = updateCurrentPage(
          this.total,
          this.fetchParams.page,
          this.fetchParams.page_size
        )
      }
      const params = {
        page: this.fetchParams.page,
        page_size: this.fetchParams.page_size
      }
      if (this.searchText) params.name = this.searchText
      getEventSourceList(params).then(res => {
        this.dataSource = res.data.data
        this.total = res.data.total
      }).finally(() => { this.spinning = false })
    },
    collapseIcon (key) {
      return this.activeKey.findIndex(item => item === key) !== -1
        ? 'up-circle'
        : 'down-circle'
    },
    handleSearch () {
      this.page = 1
      this.fetch()
    },
    async confirm (id = '') {
      if (id) {
        await deleteEventSource(id).then(res => {
          this.$message.success(res.message)
        })
        this.fetch(true)
      } else this.fetch()
    }
  }
}
</script>

<style lang="less">
.event-source-wall {
  .event-source-search {
    width: 240px;
    .ant-input {
      background-color: #fff;
    }
  }
  .ant-collapse-borderless {
    background: transparent;

    & > .ant-collapse-item {
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;

      .collapse-title {
        padding-left: 24px;
        position: relative;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        height: 22px;
        line-height: 22px;

        &::before {
          content: '';
          position: absolute;
          width: 4px;
          height: 20px;
          top: 1px;
          left: 10px;
          background: #096dd9;
          border-radius: 2px;
        }

        .collapse-icon {
          color: #5e98e3;
          font-size: 12px;
          vertical-align: middle;
          margin: 0 0 3px 8px;
        }
      }
    }
  }
}
</style>
