<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? '事件源编辑' : '事件源创建'"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="Key" prop="key">
        <a-input v-model="form.key" :disabled="isUpdate" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="接收方式" prop="method">
        <a-select
          v-model="form.method"
          :disabled="isUpdate"
          :options="options"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item label="跳转 URL" prop="redirectUrl" :wrapper-col="{ span: 20 }">
        <a-input
          v-model="form.redirectUrl"
          :max-length="128"
          placeholder="以 http:// 或 https:// 开头"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="active">
        <a-switch v-model="form.active"></a-switch>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button type="primary" @click="ok">确定</a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getEventSource, updateEventSource, createEventSource } from '@/api/event-source'

export default {
  name: 'InputDrawer',
  data () {
    return {
      visible: false,
      isUpdate: false,
      id: '',
      form: {
        name: '',
        key: '',
        method: '',
        redirectUrl: '',
        active: true
      },
      rules: {
        name: [{
          message: '请输入事件源名称',
          required: true,
          trigger: 'blur'
        }],
        key: [{
          message: '请输入事件源 Key',
          required: true,
          trigger: 'blur'
        }],
        method: [{
          message: '请选择接收方式',
          required: true,
          trigger: 'change'
        }]
      },
      options: [
        { value: 'builtin', title: '内置' },
        { value: 'kafka', title: 'Kafka' }
      ],
      loading: false
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.id = id
        getEventSource(id).then(res => {
          const data = res.data
          this.form = {
            name: data.name,
            key: data.key,
            method: data.method,
            redirectUrl: data.redirect_url,
            active: data.active
          }
        }).finally(() => {
          this.isUpdate = true
          this.visible = true
        })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        name: '',
        key: '',
        method: '',
        redirectUrl: '',
        active: true
      }
      this.$refs.form.resetFields()
      this.visible = false
      this.isUpdate = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            name: this.form.name,
            active: this.form.active
          }
          if (this.form.redirectUrl) params.redirect_url = this.form.redirectUrl
          if (this.isUpdate) {
            updateEventSource(this.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
          } else {
            params.key = this.form.key
            params.method = this.form.method
            createEventSource(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
          }
        }
      })
    }
  }
}
</script>
