<template>
  <a-button
    icon="edit"
    size="small"
    type="link"
    @click="e => $emit('click', e)"
    style="color: #faad14"
  >
    编辑
  </a-button>
</template>

<script>
export default {
  name: 'EditButton'
}
</script>
