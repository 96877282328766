<template>
  <div class="container">
    <div class="header">
      <a style="cursor: default;">{{ eventSource.name }}</a>
      <span class="more-btn">
        <a-dropdown>
          <a-icon type="ellipsis" style="cursor: pointer" />
          <a-menu slot="overlay">
            <a-menu-item>
              <edit-button v-permission="{action: 'base.event_source.update', effect: 'disabled'}" @click="$refs.inputDrawer.show(eventSource.id)"></edit-button>
            </a-menu-item>
            <a-menu-item>
              <delete-button v-permission="{action: 'base.event_source.delete', effect: 'disabled'}" @confirm="$emit('ok', eventSource.id)"></delete-button>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </div>
    <div class="card-body">
      <a-descriptions :column="1">
        <a-descriptions-item label="Key">
          {{ eventSource.key }}
        </a-descriptions-item>
        <a-descriptions-item label="接收方式">
          {{ $t(`event_source_method.${eventSource.method}`) }}
        </a-descriptions-item>
        <a-descriptions-item label="跳转 URL">
          {{ eventSource.redirect_url }}
        </a-descriptions-item>
        <a-descriptions-item label="状态">
          <boolean-tag :value="eventSource.active"></boolean-tag>
        </a-descriptions-item>
      </a-descriptions>
    </div>

    <input-drawer ref="inputDrawer" @ok="$emit('ok')"></input-drawer>
  </div>
</template>

<script>
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import BooleanTag from '@/components/tag/BooleanTag.vue'
import InputDrawer from './InputDrawer.vue'

export default {
  name: 'EventSourceCard',
  components: {
    BooleanTag,
    DeleteButton,
    EditButton,
    InputDrawer
  },
  props: {
    eventSource: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.container {
  height: 224px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 16px;
  cursor: default;
}
.header {
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 48px;
  box-sizing: border-box;
}
.more-btn {
  float: right;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.25);
}
.card-body {
  padding: 16px;
}
.container >>> .ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.55);
}
.container >>> .ant-descriptions-item-content {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
</style>
